import fetch from 'react-storefront/fetch';
import https from 'https';

export async function apiFetch(endpoint, method, body = {}, req, includeSeoUrls = true, isCheckout = false) {
	const {cartRecovery} = req.query;
	const url = `${req.currentDomainConfig.apiUrl}/${endpoint}`;
	const swToken = cartRecovery || req.cookies[process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE];
	const clientIp = req.headers['x-forwarded-for'] || req.socket.remoteAddress;

	let fetchObject = {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			'sw-access-key': req.currentDomainConfig.accessKey,
			'sw-inheritance': true,
		}
	}, res;

	if (!clientIp.includes('127.0.0.1') && clientIp !== '::1') {
		fetchObject.headers['CF-Connecting-IP'] = req.headers['x-forwarded-for'] || req.socket.remoteAddress;
	}

	// Added extra seoUrl info to response as long as include flag is not false
	if(includeSeoUrls) {
		fetchObject.headers['sw-include-seo-urls'] = true;
	}

	if(isCheckout) {
		fetchObject.headers['sw-is-checkout'] = true;
	}

	// Do not add body object if request is get
	if(method != 'get') {
		fetchObject.body = JSON.stringify(body);
	}

	// If swToken is set, ut it in subsequent requests
	if(swToken && endpoint !== process.env.STOREFRONT_PWA_PAGE_ENDPOINT) {
		fetchObject.headers['sw-context-token'] = swToken;
	}

	if(process.env.ENVIRONMENT === 'Development' && url.indexOf('https') >= 0) {
		fetchObject.agent = new https.Agent({
		  	rejectUnauthorized: false,
		});
	}

	res = await fetch(url, fetchObject);

	if(res.status >= 400) {
		let text = await res.json();
		return {status: 'error', type: res.status, error: text};
	}

	let data;

	if(res.status !== 204) {
		try {
			data = await res.json();
		} catch (e) {
			let text = await res.text();
			return {status: 'error', type: res.status, error: text};
		}
	}

	return {
		status: 'success',
		headers: {'sw-context-token': res.headers.get('sw-context-token')},
		data: {
			...data
		}
	}
}
