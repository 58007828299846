export function getIdsFromListData(listData) {
	let productIds = [];
	listData.data.listing.elements.forEach((product, index) => {
		if (index < 3) {
			productIds.push(product.productNumber);
		}
	});
	return productIds;
}

export function getNewParams(page, query) {
	let newParams = {
		...query
	};
	if (page > 1) {
		newParams.page = page;
	} else {
		delete newParams.page;
	}
	return newParams;
}

export function getNewSortingParams(page, query, data) {
	let newParams = {
		...query,
		order: data.value
	};
	if (page > 1) {
		newParams.page = 1;
	}
	return newParams;
}

export function getNewFilterParams(page, query, attribute, data, availableFilters) {
	let queryKeys = Object.keys(query);
	let availableFilterKeys = Object.keys(availableFilters);

	let cleanQuery = {};
	queryKeys.map((filterKey) => {
		if(availableFilterKeys.indexOf(filterKey) >= 0) {
			cleanQuery[filterKey] = query[filterKey];
		}
	});

	let newParams = {
		...cleanQuery
	};

	if (page > 1) {
		newParams.page = 1;
	}

	newParams[attribute] = formatFilter(attribute, data, availableFilters);
	return newParams;
}

export function updateRouterData({ page, order, initialPage, pathname, filters, availableFilters }) {
	let routerData = {
		pathname,
		query: {}
	},
	replace;

	if (!!filters && !!availableFilters) {
		routerData.query = { ...filters ? formatFilters(filters, availableFilters) : {} };
	}

	if (!!order) {
		routerData.query.order = order;
	}

	if (!!page && page !== initialPage) {
		routerData.query.page = page;
		replace = true;
	}

	return {
		routerData,
		replace
	};
}

export function paramsHash(query) {
	let paramsObject = { ...query };

	delete paramsObject.page;

	return typeof btoa !== 'undefined'
		? btoa(JSON.stringify(paramsObject))
		: Buffer.from(JSON.stringify(paramsObject)).toString('base64');
}

export function formatFilters(currentFilters, availableFilters) {
	let initialFilters = Object.keys(currentFilters).map(filterKey => {
		let filter = currentFilters[filterKey];
		return { key: filterKey, value: filter };
	});

	let preparedFilters = {};
	initialFilters.forEach(filter => {
		if (!filter.value) {
			return;
		}

		preparedFilters[filter.key] = formatFilter(filter.key, filter.value, availableFilters);
	});

	return preparedFilters;
}

export function formatFilter(key, value, availableFilters) {
	const rangeFilters = process.env.NEXT_PUBLIC_STOREFRONT_RANGE_FILTERS.split(',');
	if (Array.isArray(value) && value.length > 0) {
		return value.join('|');
	} else if (rangeFilters.indexOf(key) >= 0) {
		let filter = availableFilters[key];

		if (filter && parseInt(filter.min) === value.min && parseInt(filter.max) === value.max) {
			return;
		}

		if (value.min) {
			return value.min + ',' + value.max;
		} else {
			return value;
		}
	} else {
		return value;
	}
}

export function prepareSorting(sorting) {
	return sorting.map(sort => {
		return {
			value: sort.key,
			label: sort.translated.label
		};
	});
}

export function getAllProducts(products, prodListData, pagination) {
	const productListing = prodListData.data ? prodListData.data.listing : { elements: [], limit: 48, page: 1 };
	let allProducts = [...products];

	if (productListing.page && productListing.page > pagination.initialPage) {
		allProducts.push(...prodListData.data.listing.elements);
	} else if (productListing.page && productListing.page < pagination.initialPage) {
		allProducts.unshift(...prodListData.data.listing.elements);
	} else {
		allProducts = prodListData.data.listing.elements;
	}
	return allProducts;
}

export function isPropertyKey(key, availableFilters) {
	return availableFilters?.properties?.entities.find(property => property.customFields.attributeCode === key);
}

function enrichAvailableFilters(shouldEnrich = false, availableFilters, sessionFilters, prodListData) {
	let filterKeys = Object.keys(prodListData.data.listing.currentFilters);
	const properties = availableFilters.properties || null;
	const hasProperties = !!properties && properties.entities?.length;

	if (hasProperties && shouldEnrich) {
		filterKeys = filterKeys.filter(key => key !== 'properties');
		// 1. Add properties keys to filterKeys
		properties.entities?.forEach(property => {
			const code = property?.translated?.customFields?.attributeCode;

			if(code) {
				filterKeys.push(code);

				if (!sessionFilters.find(f => f === code)) {
					sessionFilters.push(property.translated?.customFields.attributeCode);
				}

				if (!availableFilters[code]) {
					availableFilters[code] = {
						name: property.translated?.customFields.attributeCode,
						label: property.translated.name,
						entities: property.options,
						isProperty: true
					};
				}
			}
		});
	}

	return filterKeys;
}

export function prepareFilters(availableFilters, sessionFilters, prodListData, shouldEnrich = false) {
	const filterKeys = enrichAvailableFilters(shouldEnrich, availableFilters, sessionFilters, prodListData);
	const filters = {};

	filterKeys.forEach(filterKey => {
		let filter = availableFilters[filterKey];
		if (filter && sessionFilters.indexOf(filterKey) >= 0) {
			if (filter.hasOwnProperty('entities')) {
				const options = [];
				filter.entities.forEach(option => {
					options.push({
						value: encodeURIComponent(option.id),
						label: option.translated.name
					});
				});

				filters[filterKey] = {
					label: filter.label,
					key: filterKey,
					type: 'dropdown',
					options: options
				};
			} else if (filter.hasOwnProperty('min') && filter.hasOwnProperty('max')) {
				filters[filterKey] = {
					label: filter.label,
					key: filterKey,
					type: 'range',
					...filter
				};
			}
		}
	});

	return filters;
}
