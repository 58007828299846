import { ListItem } from '@material-ui/core';
import Link from 'next/link';
import React from 'react';
import SearchIcon from "../../../../public/images/icons/search-thin.svg";

export default function SearchSuggestion({ props }) {
	const { suggestion, index, query, classes, t, focus } = props;
	let text = suggestion.highlighted.replaceAll('{', '<strong>').replaceAll('}', '</strong>');

	return (
		<ListItem className={[classes.suggestion, focus ? 'focus' : ''].join(' ')} key={`${index}-${encodeURIComponent(text)}`} disableGutters>
			<Link href={{
				pathname: t('search-url'),
				query: { q: suggestion.q }
			}}>
				<a>
					<SearchIcon className={'searchIcon'} width={12}/>
					<span  dangerouslySetInnerHTML={{__html: text}}></span>
				</a>
			</Link>
		</ListItem>
	);
}
